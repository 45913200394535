import { Text } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';

interface NotificationsTableHeaderProps {
	header: string;
}

const NotificationsTableHeader = ({
	header,
}: NotificationsTableHeaderProps) => {
	return (
		<Text strong className="notifications-table-header">
			{header}
		</Text>
	);
};

export default NotificationsTableHeader;
