import { useState } from 'react';
import {
	Button,
	Flex,
	Segmented,
} from '@opsdti-global-component-library/amgen-design-system';
import NotificationsTableHeader from '../../../tables/notifications-table-header';
import ApprovalRecipientsTable from './approval-recipients-table';
import sortData from '../../../../utils/sort-data';
import { notAvailable } from '../../../../constants';
import { RequestLogModalSettings, UserAdGroupToggle } from '../../../../types';
import './index.scss';

type RecipientsDataType = {
	user_name: string;
	full_name: string;
	user_type: string;
	delivery_channel: string[];
	subscription_delivery_status: {
		[key: string]: string;
	};
	users_count: string;
};

type RecipientsListType = {
	name: string;
	channel: React.ReactNode;
	usersCount: string;
};

interface RequestLogModalRecipientsProps {
	recipientsData: RecipientsDataType[];
	deliveryStatus: string;
	requestLogModalSetting: RequestLogModalSettings;
	onOpenAdUsersModal: (adGroup: string) => void;
}

const RequestLogModalRecipients = ({
	recipientsData,
	deliveryStatus,
	requestLogModalSetting,
	onOpenAdUsersModal,
}: RequestLogModalRecipientsProps) => {
	const [selectedTab, setSelectedTab] = useState<string | number>(
		requestLogModalSetting === RequestLogModalSettings.DEFAULT
			? UserAdGroupToggle.USER
			: UserAdGroupToggle.ADGROUP
	);

	const getUserButton = (selectedRow) => {
		return (
			<Button
				text={selectedRow?.users}
				onClick={() => {
					onOpenAdUsersModal(selectedRow?.name as string);
				}}
				type={'link'}
			/>
		);
	};

	const userHeader = [
		{
			dataIndex: 'name',
			key: 'name',
			title: <NotificationsTableHeader header="NAME" />,
			ellipsis: true,
			sortDirections: ['descend', 'ascend', 'descend'],
			defaultSortOrder: 'ascend',
			sorter: (a, b, sortOrder) => sortData(a?.name, b?.name, sortOrder),
		},
		{
			dataIndex: 'channel',
			key: 'channel',
			title: (
				<NotificationsTableHeader
					header={
						['Succeeded', 'Failed'].includes(deliveryStatus)
							? 'DELIVERY STATUS'
							: 'CHANNEL'
					}
				/>
			),
			width: '205px',
		},
	];

	const adminHeader = [
		{
			key: 'name',
			dataIndex: 'name',
			title: <NotificationsTableHeader header="NAME" />,
			width: '144px',
			ellipsis: true,
			sortDirections: ['descend', 'ascend', 'descend'],
			defaultSortOrder: 'ascend',
			sorter: (a, b, sortOrder) => sortData(a?.name, b?.name, sortOrder),
		},
		{
			key: 'users',
			dataIndex: 'usersCount',
			render: (_, data1) => getUserButton(data1),
			title: <NotificationsTableHeader header="USERS" />,
		},
		{
			key: 'channel',
			dataIndex: 'channel',
			title: <NotificationsTableHeader header="CHANNEL" />,
			width: '144px',
		},
	];

	// retrieve and format the recipients list based on the selected tab (Users or AD groups)
	const recipientsList =
		!notAvailable.includes(deliveryStatus) && recipientsData
			? recipientsData.reduce((result: RecipientsListType[], recipient) => {
					if (
						recipient?.user_type === 'AD'
							? UserAdGroupToggle.ADGROUP
							: UserAdGroupToggle.USER === selectedTab
					) {
						result.push({
							name:
								selectedTab === UserAdGroupToggle.USER
									? recipient?.full_name.split(', ').reverse().join(' ')
									: recipient?.full_name,
							channel: (
								<Flex vertical>
									{recipient?.delivery_channel.map((channel) => {
										const deliveryChannel = channel.includes('teams')
											? 'MS Teams'
											: channel.includes('email')
											? 'Email'
											: 'SMS';
										return (
											<span key={deliveryChannel}>
												{deliveryChannel}
												{['Succeeded', 'Failed'].includes(deliveryStatus) ? (
													<strong>{` - ${
														recipient?.subscription_delivery_status[channel] ===
														'SUCCESS'
															? 'Success'
															: recipient?.subscription_delivery_status[
																	channel
															  ] === 'FAILED'
															? 'Failed'
															: recipient?.subscription_delivery_status[channel]
													}`}</strong>
												) : (
													''
												)}
											</span>
										);
									})}
								</Flex>
							),
							usersCount:
								selectedTab === UserAdGroupToggle.ADGROUP
									? recipient?.users_count
									: '',
						});
					}
					return result;
			  }, [])
			: [];

	return (
		<div>
			<div className="notifications-table-left-controls">
				<Segmented
					value={selectedTab}
					onChange={(value: string | number) => {
						setSelectedTab(value);
					}}
					options={[UserAdGroupToggle.USER, UserAdGroupToggle.ADGROUP]}
				/>
			</div>
			<div className="notifications-table-container">
				{selectedTab === UserAdGroupToggle.USER ? (
					<ApprovalRecipientsTable
						key="request-log-users-table"
						header={userHeader}
						data={recipientsList}
					/>
				) : (
					<ApprovalRecipientsTable
						key="request-log-ad-groups-table"
						header={adminHeader}
						data={recipientsList}
					/>
				)}
			</div>
		</div>
	);
};

export default RequestLogModalRecipients;
