import { Modal,   } from '@gitlab-rtsensing/component-library';
import { useState } from 'react';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Button, Spin } from '@opsdti-global-component-library/amgen-design-system';

interface NewsLetterType {
  news_letters: string;
  total_views: number;
}
interface NewsLetterProps {
  opened: boolean;
  newsLetterModalClosed: (val: boolean) => void;
  newsLetterData: NewsLetterType[];
  setReloadChart: () => void;
}
export default function NewsLetterModal(props: NewsLetterProps): JSX.Element {
  const { opened, newsLetterModalClosed, newsLetterData, setReloadChart } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [inputValUpdate, setInputValUpdate] = useState<NewsLetterType[]>(newsLetterData);
  const [newQtrVal, setNewQtrVal] = useState('');
  const updateViewCountInfo = (e: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    let inputValue = Number(e.target.value);
    let qtrVal = getQtrAndyear();
    if (indx === -1 && qtrVal != null) {
      setNewQtrVal(inputValue.toString());
    } else {
      let modifiedArray: NewsLetterType[] = inputValUpdate.map((data, pos) => {
        return pos === indx ? Object.assign(data, (data.total_views = inputValue)) : data;
      });
      setInputValUpdate(modifiedArray);
    }
  };

  const getQtrAndyear = () => {
    let dt = new Date();
    let month = dt.getMonth() + 1;
    let yr = dt.getFullYear();
    let returnVal = '';
    let lastInputQtr = String(inputValUpdate[inputValUpdate.length - 1].news_letters).replaceAll(' ', '');

    if (month < 4) {
      returnVal = 'Q4 ' + (yr - 1);
    } else if (month < 7) {
      returnVal = 'Q1 ' + yr;
    } else if (month < 10) {
      returnVal = 'Q2 ' + yr;
    } else {
      returnVal = 'Q3 ' + yr;
    }

    if (lastInputQtr === returnVal.replaceAll(' ', '')) {
      return null;
    } else {
      return returnVal;
    }
  };

  /*
   ** @description: API Call to update the NewsLetter Info
   **/

  const submitAPI = async () => {
    const getQtrVal = getQtrAndyear();
    if (getQtrVal !== null && newQtrVal !== '') {
      inputValUpdate.push({ news_letters: getQtrVal, total_views: Number(newQtrVal) });
    }

    if (inputValUpdate.filter(data => data.total_views === 0).length > 0) {
      toast.error('Empty Values not allowed');
      return;
    }
    try {
      //console.log(inputValUpdate);
      const payload = {
        news_letters_views_list: inputValUpdate,
      };
      setIsLoading(true);
      const res = await apiResponse('post', 'post-newsletter', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setIsLoading(false);
        setReloadChart();
        toast.success('Information has been updated');
        onModalClose();
      } else {
        toast.error('NewsLetter Update API Failed');
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onModalClose = () => {
    setReloadChart();
    newsLetterModalClosed(false);
    //Glitch with page Y scroll When modal is opened
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  };

  return (
    <Modal className="description-modal newsletter-modal" opened={opened} onClose={() => onModalClose()}>
      <Modal.Header contentClassName="d-flex align-items-center" className="description-modal-header">
        <Modal.Title className="description-modal-title" title={'Add/Update Information'} />
      </Modal.Header>
      <div className="description-modal-body">
        {isLoading ? (
          <div className="adm-loader-container">
            <Spin />
          </div>
        ) : (
          <div className="description-modal-table-container">
            <table className="usage-metric-table">
              <thead>
                <tr>
                  <th>Quarter</th>
                  <th>Total View</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {inputValUpdate.map((data, indx) => {
                    return (
                      <tr key={indx}>
                        <td>{data.news_letters}</td>
                        <td>
                          <input
                            className="description-input"
                            type="number"
                            min={0}
                            value={data.total_views === 0 ? '' : data.total_views}
                            placeholder="Enter Numeric Count"
                            data-input-id={data.news_letters}
                            onChange={e => updateViewCountInfo(e, indx)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  {getQtrAndyear() === null ? null : (
                    <tr>
                      <td>{getQtrAndyear()}</td>
                      <td>
                        <input
                          className="description-input"
                          type="number"
                          min={0}
                          value={newQtrVal === '0' ? '' : newQtrVal}
                          placeholder="Enter Numeric Count"
                          data-input-id={getQtrAndyear()}
                          onChange={e => updateViewCountInfo(e, -1)}
                        />
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </table>
            <Button text="Save" type="secondary" className="save-btn" onClick={submitAPI}></Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
