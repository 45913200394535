import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import { Button, Modal } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { useState } from 'react';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { ComponentDescriptionModalProps, ComponentParamType } from '../user-audit-interface';

export default function ComponentDescriptionModal(props: ComponentDescriptionModalProps): JSX.Element {
  const { opened, descriptionModalClosed, descriptionData, setTriggerComponetUpdate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [inputDescription, setInputDescription] = useState<{ [key: string]: string }[]>(() => {
    return descriptionData.map(data => Object.assign({}, { [data.id]: data.description }));
  });
  const [inputValUpdate, setInputValUpdate] = useState<{ [key: string]: string }[]>();

  const updateDescriptionEvent = (e: React.ChangeEvent<HTMLTextAreaElement>, indx: any) => {
    let inputId: string = e.target.getAttribute('data-input-id') || '';
    let inputValue: string = e.target.value;
    //let indx = Number.isInteger(indx) ? (indx = indx) : (indx = 0);
    let inputObj = inputDescription[indx];

    setInputDescription(() => {
      let newObj = Object.assign({}, inputObj, { [inputId]: inputValue });
      let newArr = [...inputDescription];
      newArr[indx] = newObj;
      return newArr;
    });
    setInputValUpdate(() => {
      let newObj = Object.assign({}, inputObj, { [inputId]: inputValue });
      let newArr = typeof inputValUpdate === 'undefined' ? [] : [...inputValUpdate];
      newArr[indx] = newObj;
      return newArr;
    });
  };

  const submitAdGrpDescription = () => {
    if (typeof inputValUpdate === 'undefined') return;

    let submitObj = [];
    for (let i = 0; i < inputValUpdate?.length; i++) {
      if (typeof inputValUpdate[i] !== 'undefined') {
        //Pattern: pipeline-#key_products-#amg451
        let key = Object.keys(inputValUpdate[i])[0];
        let value = Object.values(inputValUpdate[i])[0];
        let valueSet = key.split('-#');
        submitObj.push({
          work_stream_name: valueSet[0],
          component_name: valueSet[1],
          subcomponent_name: valueSet[2] === '' ? 'N/A' : valueSet[2], //For Blank Subcomponent 'N/A' is set in backend
          description: value,
        });
      }
    }
    updateDescription(submitObj);
  };

  /*
   ** @description: API Call to update the description
   **/

  const updateDescription = async (descriptionObj: {}[]) => {
    const payload = {
      des_list: descriptionObj,
    };
    try {
      setIsLoading(true);
      const res = await apiResponse('post', 'update-component-description', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setIsLoading(false);
        toast.success('Component Description has been updated');
        descriptionModalClosed(false);
        setTriggerComponetUpdate(!props.triggerComponentDescriptionUpdate);
      } else {
        console.log('Component Description Update API Failed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      className="description-modal"
      open={opened}
      maskCloseable={false}
      onCancel={() => descriptionModalClosed(false)}
      title={
        <span className="description-modal-header">
          <h3 className="description-modal-title">Update Description</h3>
        </span>
      }
      width={500}
    >
      <div className="description-modal-body">
        {isLoading ? (
          <div className="adm-loader-container">
            <Spin />
          </div>
        ) : (
          <div className="description-modal-table-container">
            <table className="usage-metric-table">
              <thead>
                <tr>
                  <th>Page</th>
                  <th>Component Hierarchy</th>
                  <th>Component Description</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {descriptionData.map((data: ComponentParamType, indx) => {
                    return (
                      <tr key={indx}>
                        <td>{data.page}</td>
                        <td>
                          {data.tile}
                          {data?.subComp?.toLowerCase() === '' ? '' : String(':' + data.subComp)}
                        </td>
                        <td>
                          <textarea
                            className="description-textarea"
                            value={inputDescription[indx][data.id]}
                            placeholder="Enter Description"
                            autoFocus={false}
                            data-input-id={data.id}
                            onChange={e => updateDescriptionEvent(e, indx)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </table>
          </div>
        )}
        {isLoading ? <></> : <Button text="Save" type="secondary" className="save-btn" onClick={submitAdGrpDescription}></Button>}
      </div>
    </Modal>
  );
}
