import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { apiResponse, filterMembers } from '../../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import BarGraph from '../../../bar-graph';
import { PageLevelUsageProps } from '../../usage-metric-interface';

const PageUsageChart = (props: PageLevelUsageProps) => {
  const [isLoading, setLoading] = useState(true);
  const [getPageDetails, setPageDetails] = useState([]);
  const [originalPageDetails, setOriginalPageDetails] = useState([]);

  useEffect(() => {
    if (props.clearData) {
      setPageDetails([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    const filterGroupData = filterMembers(originalPageDetails, props.userGroupValue, props.includeCoreMember);

    let dataList: any = []; //for merging core-member and non-core member data
    filterGroupData.map((value: any, index: any, self: any) => {
      if (index === self.findIndex((t: any) => t.page_title == value.page_title)) {
        dataList.push({
          fullname: value.page_title,
          visitcount: value.count_visits,
        });
      } else {
        dataList.map((data: any) => {
          if (data.fullname === value.page_title) {
            data.fullname = value.page_title;
            data.visitcount = Number(data.visitcount) + Number(value.count_visits);
          }
        });
      }
    });

    dataList.sort((a: any, b: any) => {
      if (Number(a.visitcount) > Number(b.visitcount)) {
        return -1;
      } else if (Number(a.visitcount) < Number(b.visitcount)) {
        return 1;
      } else {
        return 0;
      }
    });

    //for removing home in list
    const finalList = dataList.filter((page: any) => {
      return page.fullname !== 'home';
    });
    setPageDetails(finalList);
  }, [props.userGroupValue, props.includeCoreMember, originalPageDetails]);

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '13',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length > 0) {
            const pageDetails = res.data.data.user_metric;
            setOriginalPageDetails(pageDetails);
            props.setClearData(false);
          } else {
            toast.error('Data not available!!');
          }
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  return (
    <div className="kpi-metric">
      <div className="metric-header"></div>
      {isLoading && getPageDetails.length === 0 ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <div className="kpi-metric-container">
          <BarGraph chartType="page-usage" data={getPageDetails} top={0} bottom={0} left={20} right={20} />
        </div>
      )}
    </div>
  );
};
export default PageUsageChart;
