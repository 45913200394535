/* eslint-disable react-hooks/rules-of-hooks */
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
//import { gql } from '@apollo/client/react/hoc';
import { useMemo, useRef, useState, useEffect } from 'react';
export const useApolloClientWithAuth: any = () => {
  const token: string = getOktaToken();
  const { current: cache } = useRef(new InMemoryCache());
  const client = useMemo(() => {
    return new ApolloClient({
      uri: process.env.REACT_APP_AUTH_LINK,
      cache: new InMemoryCache(),
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }, [token, cache]);
  return client;
};
export const useUsersNames: any = (state: boolean) => {
  const [fullName] = useState<string>(JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims.name ?? '');
  const [firstName] = useState<string>(JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims?.name?.split(' ')[1] ?? '');
  const [emailId] = useState<string>(JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims?.email);
  return { fullName, firstName, emailId };
};

export const authorizationApi: (state: boolean) => any = (state: boolean) => {
  const client: any = useApolloClientWithAuth();
  const { emailId } = useUsersNames(state);
  const [authorizationState, setAuthorizationState] = useState<any>({
    data: [],
    error: null,
    isLoading: false,
  });
  useEffect(() => {
    setAuthorizationState({
      ...authorizationState,
      isLoading: true,
    });
    if (emailId) {
      client
        .query({
          variables: {
            username: emailId.split('@')[0],
            workstreams: ['admin'],
            skipWorkstreamLevelAuth: false,
          },
          query: gql`
            query sensingAuthorization($username: String!, $workstreams: [String!], $skipWorkstreamLevelAuth: Boolean) {
              sensingAuthorization(username: $username, workstreams: $workstreams, skipWorkstreamLevelAuth: $skipWorkstreamLevelAuth) {
                admin
                adminAuthorization {
                  usageMetric
                  userAudit
                  sensingAccessRequest
                  levelOne
                  levelTwo
                  featureOwner
                  releaseNotes
                  releaseNotesPm
                  accessLog
                  userAuditView
                  etlMonitoring
                  dataElementsInventory
                  sensingConstants
                  brandRefresh
                  gptFeedback
                  peopleVpMapping
                  etlJobTrigger
                }
              }
            }
          `,
        })
        .then((response: any) => {
          setAuthorizationState({
            ...authorizationState,
            isLoading: false,
            data: response.data,
          });
        })
        .catch((e: any) => {
          console.log('auth', e);
          setAuthorizationState({
            ...authorizationState,
            isLoading: false,
            data: null,
            error: e,
          });
        });
    }
  }, [emailId]);
  return authorizationState;
};
export const getOktaDetails = () => {
  const oktaToken: any = localStorage.getItem('sensing-okta-token');
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj?.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};
export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return '';
};
