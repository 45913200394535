import {
	Button,
	Table,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import NotificationsTableHeader from '../notifications-table-header';
import { useContext } from 'react';
import { AppContext } from '../../../state/app-context';
import { UserAdGroupToggle } from '../../../types';
import sortData from '../../../utils/sort-data';

interface ManageUsersTableProps {
	data: any;
	tableClassName: string;
	selectedTab: string | number;
	updateUserModalState: (state: boolean) => void;
	setManageUserData: (data: any) => void;
}

const ManageUsersTable = ({
	data,
	tableClassName,
	selectedTab,
	updateUserModalState,
	setManageUserData,
}: ManageUsersTableProps) => {
	const { tableContentLoader } = useContext(AppContext);

	// Buttons to be renderd in the Table component
	const getActionButton = (selectedRow) => {
		return (
			<Flex justify="center">
				<Button
					text="Manage"
					onClick={() => {
						updateUserModalState(true);
						setManageUserData(selectedRow);
					}}
					type="secondary"
				/>
			</Flex>
		);
	};

	return (
		<Table
			className={tableClassName}
			bordered
			showSorterTooltip={false}
			columns={[
				{
					dataIndex: 'full_name',
					key: 'full_name',
					title: <NotificationsTableHeader header="NAME" />,
					width: '326px',
					sortDirections: ['descend', 'ascend', 'descend'],
					defaultSortOrder: 'ascend',
					sorter: (a, b, sortOrder) =>
						sortData(a?.full_name, b?.full_name, sortOrder),
				},
				{
					dataIndex: 'active_subscriptions',
					key: 'active_subscriptions',
					title: <NotificationsTableHeader header="ACTIVE SUBSCRIPTIONS" />,
					width: '326px',
				},
				{
					dataIndex:
						selectedTab === UserAdGroupToggle.USER ? 'userid' : 'users_count',
					key: 'email/users_count',
					render: (_, row_data) =>
						selectedTab === UserAdGroupToggle.USER
							? String(row_data?.userid) + '@amgen.com'
							: row_data?.user_count,
					sortDirections:
						selectedTab === UserAdGroupToggle.USER
							? ['descend', 'ascend', 'descend']
							: undefined,
					title: (
						<NotificationsTableHeader
							header={
								selectedTab === UserAdGroupToggle.USER ? 'EMAIL' : 'USERS'
							}
						/>
					),
					sorter:
						selectedTab === UserAdGroupToggle.USER
							? (a, b, sortOrder) => sortData(a?.userid, b?.userid, sortOrder)
							: undefined,
				},
				{
					dataIndex: 'actions',
					key: 'actions',
					render: (_, row_data) => getActionButton(row_data),
					title: <NotificationsTableHeader header="ACTIONS" />,
					width: '85px',
					fixed: 'right',
				},
			]}
			dataSource={data}
			style={{
				width: '100%',
			}}
			scroll={{
				x: '1067px',
			}}
			loading={tableContentLoader}
			pagination={{
				position: ['none', 'bottomRight'],
				showSizeChanger: true,
				showTotal: (total: number, range: [number, number]) =>
					`${range[0]}-${range[1]} of ${total} items`,
			}}
		/>
	);
};

export default ManageUsersTable;
