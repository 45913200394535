import {
	Label,
	Table,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import NotificationsTableHeader from '../../../tables/notifications-table-header';
import sortData from '../../../../utils/sort-data';
import '../index.scss';

interface AdUsersTableProps {
	adUsers: any;
}

const AdUsersTable = ({ adUsers }: AdUsersTableProps) => {
	const { token } = theme;

	return (
		<>
			<Flex vertical gap={8}>
				<Label
					style={{
						color: token?.colorText,
						marginTop: '4px',
						marginLeft: '1px',
					}}
				>
					{`${adUsers.length} users`}
				</Label>
				<Table
					key="manage-ad-users-table"
					bordered={false}
					showSorterTooltip={false}
					columns={[
						{
							dataIndex: 'fullname',
							key: 'user_name',
							sortDirections: ['descend', 'ascend', 'descend'],
							defaultSortOrder: 'ascend',
							title: <NotificationsTableHeader header="USER NAME" />,
							sorter: (a, b, sortOrder) =>
								sortData(a?.fullname, b?.fullname, sortOrder),
							width: '142px',
							ellipsis: true,
						},
						{
							dataIndex: 'loginname',
							key: 'user_id',
							title: <NotificationsTableHeader header="USER ID" />,
							width: '86px',
							ellipsis: true,
						},
						{
							dataIndex: 'position',
							key: 'position',
							title: <NotificationsTableHeader header="POSITION" />,
							ellipsis: true,
						},
					]}
					dataSource={adUsers}
					scroll={{
						x: '300px',
						y: adUsers.length > 10 ? '350px' : undefined,
					}}
					size="small"
					style={{
						width: '434px',
					}}
				/>
			</Flex>
		</>
	);
};

export default AdUsersTable;
