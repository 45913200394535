import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import SubscriptionManagerApp from './app';
import './style/app.scss';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';

const reactLifecycles = singleSpaReact({
	React,
	ReactDOM,
	rootComponent: SubscriptionManagerApp,
	errorBoundary() {
		return (
			<Flex align="center" justify="center" className="notifications-error">
				Something went wrong!
			</Flex>
		);
	},
});

export const bootstrap = reactLifecycles.bootstrap;
export const mount = [
	(props: any) => {
		if (!props?.permissions?.notificationsSubscriptions) {
			props.singleSpa.navigateToUrl('/unauthorized');
		}
		return reactLifecycles.mount(props);
	},
];
export const unmount = [(props: any) => reactLifecycles.unmount(props)];
