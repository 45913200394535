import React, { useMemo, useState, useEffect } from 'react';
import DataTable from '../../data-table';
import { Ad_group_allocation_Table_Header } from './AdGrpAlloactionTableHeader';
import { Button, Spin } from '@opsdti-global-component-library/amgen-design-system';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';

const AdGroupAllocator: React.FC = () => {
  const columns = useMemo(() => Ad_group_allocation_Table_Header, []);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [adGroupApprov_ReqList, setADGroupApprov_ReqList] = useState([{}]);
  const [adGroupList, setADGroupList] = useState([]);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    getADGroupApprovalRequest();
    getADGroupList();
  }, []);

  useEffect(() => {
    if (adGroupApprov_ReqList.length > 0) {
      setDisabled(false);
    } else setDisabled(true);
  }, [adGroupApprov_ReqList]);

  const getADGroupApprovalRequest = async () => {
    const payload = {
      approval_level: 'allocation',
    };
    try {
      setLoading(true);
      const res = await apiResponse('post', 'get-access-request-list', [], payload);
      if (res?.data.status === 'SUCCESS') {
        let tableData = res?.data.data.request_details;
        setData(tableData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getADGroupList = async () => {
    try {
      const res = await apiResponse('post', 'get-ad-group-list', [], []);
      if (res?.data.data.allowed_ad) {
        setADGroupList(res?.data.data.allowed_ad);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitFeatureOwnReq = async () => {
    setLoading(true);
    const payload = {
      approval_request_list: adGroupApprov_ReqList,
    };
    try {
      setDisabled(true);
      const res = await apiResponse('post', 'submit-requests', [], payload);
      if (res?.data.data) {
        toast.success('Request has been created for adding user to RT-Sensing application.');
        getADGroupApprovalRequest();
      } else {
        toast.error('Failed to Submit Request');
      }
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log(error);
      toast.error('Failed to Submit Request');
    }
  };

  return (
    <>
      <div className="table-container">
        {isLoading ? (
          <div className="adm-loader-container">
            <Spin
              size="large"
            />
          </div>
        ) : (
          <div>
            <DataTable columns={columns} data={data} handleTaskClick={setADGroupApprov_ReqList} component="allocation" AD_Group_List={adGroupList} />
            <div className="d-flex justify-content-end ">
              <Button text="Submit" type="primary" onClick={() => submitFeatureOwnReq()} className="admin-btn" disabled={isDisabled}></Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdGroupAllocator;
