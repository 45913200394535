import {
	Button,
	Flex,
	Modal,
	Paragraph,
	StatusIcon,
	Title,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useEffect, useCallback, useState } from 'react';
import { AppContext } from '../../../state/app-context';
import {
	FetchNotificationResponse,
	SubscriptionAction,
	ValidationStatusType,
	RetriggerNotificationRequestData,
} from '../../../types';
import NotificationsLoader from '../../reusable/notifications-loader';
import { retriggerNotification } from '../../../services/retrigger-notification';
import './index.scss';
interface ValidationModalProps {
	title: string;
	status: ValidationStatusType;
	header: string;
	content: string;
	open: boolean;
	notification?: FetchNotificationResponse | undefined;
	retriggerRequestData?: RetriggerNotificationRequestData;
	isRequestLog?: boolean;
	handleSubscription?: (
		action: SubscriptionAction,
		notificationUpdates: any
	) => void;
	onModalDismiss: (resetRoute?: boolean, status?: ValidationStatusType) => void;
	setOpenPreview?: () => void;
	setOpenConfirm?: (action: SubscriptionAction) => void;
}

type StatusIconType = 'success' | 'warning' | 'error' | 'missing' | 'star';

const ValidationModal = ({
	title,
	status,
	header,
	content,
	open,
	notification,
	retriggerRequestData,
	isRequestLog,
	onModalDismiss,
	handleSubscription,
	setOpenPreview,
	setOpenConfirm,
}: ValidationModalProps) => {
	const { tableContentLoader, setTableContentLoader } = useContext(AppContext);
	const [retriggerResponse, setRetriggerResponse] = useState<
		undefined | string
	>(undefined);

	const handlRetriggerNotification = useCallback(() => {
		setTableContentLoader(true);
		retriggerNotification({
			event_id: retriggerRequestData?.event_id ?? '',
			event_log_id: retriggerRequestData?.event_log_id ?? '',
			requester_id: retriggerRequestData?.requester_id ?? '',
			requester_name: retriggerRequestData?.requester_name ?? '',
		})
			.then((res) => {
				if (setOpenConfirm) {
					if (res?.data?.status === 200) {
						setOpenConfirm(SubscriptionAction?.RESEND_REQUEST);
					} else if (res?.data?.status === 307) {
						//set retriggerResponse if the response's status is 307, else retriggerResponse remains undefined
						setRetriggerResponse(res?.data?.data?.message as string);
						if (res?.data?.data?.message_type === 'processing') {
							setOpenConfirm(SubscriptionAction?.RETRIGGER_IS_PROCESSING);
						} else if (res?.data?.data?.message_type === 'in_queue') {
							setOpenConfirm(SubscriptionAction?.RETRIGGER_IS_IN_QUEUE);
						}
					}
				}
			})
			.catch((e) => console.log(e))
			.finally(() => {
				setTableContentLoader(false);
			});
	}, [retriggerRequestData, setOpenConfirm, setTableContentLoader]);

	const validationModalFooter = (
		<Flex
			align="center"
			justify="center"
			className="notifications-validation-modal-footer"
			gap={8}
		>
			{status === 'WARNING' ? (
				<>
					{setOpenPreview && (
						<Button
							text={retriggerResponse ? 'UNDERSTOOD' : 'CANCEL'}
							onClick={() => {
								if (isRequestLog) {
									onModalDismiss(false);
								} else {
									onModalDismiss();
								}
								setOpenPreview();
							}}
							type={retriggerResponse ? 'primary' : 'link'}
							style={{ flex: '1' }}
						/>
					)}
					{title === 'Re-send Notification' && !retriggerResponse ? (
						<Button
							text={
								header === 'Deny and re-send this notification?'
									? 'DENY AND RESEND'
									: 'RESEND'
							}
							onClick={() => {
								handlRetriggerNotification();
							}}
							type="primary"
							style={{ flex: '1' }}
						/>
					) : (
						handleSubscription && (
							<Button
								text="UNSUBSCRIBE"
								onClick={() => {
									handleSubscription(SubscriptionAction.DELETE_SUBSCRIBER, {
										...notification,
										is_subscribed: false,
									});
									onModalDismiss();
								}}
								type="primary"
								style={{ flex: '1' }}
							/>
						)
					)}
				</>
			) : (
				<Button
					text="CLOSE"
					onClick={() => {
						if (isRequestLog) {
							onModalDismiss(undefined, status);
						} else {
							onModalDismiss();
						}
					}}
					type="primary"
					style={{ flex: '1' }}
				/>
			)}
		</Flex>
	);

	useEffect(() => {
		if (open) {
			if (!tableContentLoader && status === 'UPDATE') {
				onModalDismiss();
			}
		}
	}, [onModalDismiss, status, tableContentLoader, open]);

	return !notification && !isRequestLog ? null : (
		<Modal
			open={open}
			onCancel={() => {
				if (!tableContentLoader) {
					if (isRequestLog) {
						onModalDismiss(undefined, status);
					} else {
						onModalDismiss();
					}
				}
			}}
			afterClose={() => {
				if (retriggerResponse) {
					setRetriggerResponse(undefined);
				}
			}}
			className="notifications-validation-modal"
			classNames={{ body: 'notifications-validation-modal-body-content' }}
			title={title}
			footer={
				tableContentLoader || status === 'UPDATE' ? null : validationModalFooter
			}
		>
			{tableContentLoader ? (
				<NotificationsLoader type="spin" />
			) : status !== 'UPDATE' ? (
				<>
					<StatusIcon
						status={status.toLowerCase() as StatusIconType}
						size="large"
					/>
					<Title
						level={3}
						className="notifications-validation-modal-body-header"
					>
						{retriggerResponse ? retriggerResponse : header}
					</Title>
					{content && (
						<Paragraph className="notifications-validation-modal-body-description">
							{content}
						</Paragraph>
					)}
				</>
			) : (
				<></>
			)}
		</Modal>
	);
};

export default ValidationModal;
