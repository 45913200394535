import './index.scss';
import { useEffect, useState, useContext } from 'react';
import DescriptionModal from '../adgrp-description-modal';
import { AppContext } from '../../../contexts/app-context';
import { DescriptionType, TableHeaderType, TableProps, ComponentParamType } from '../user-audit-interface';
import ComponentDescriptionModal from '../component-description-modal';
import { toast } from 'react-toastify';
import { Spin, InfoIcon, PlusIcon  } from '@opsdti-global-component-library/amgen-design-system';

const OverAllAccessTable = (props: TableProps) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);
  const [showComponentDescriptionModal, setShowComponentDescriptionModal] = useState<boolean>(false);
  const [componentDescriptionData, setComponentDescriptionData] = useState<ComponentParamType[]>([]);
  const adMap = new Map(props.descriptionData.map((data: DescriptionType) => [data.ad_group, data]));
  const [currentToolTip, setCurrentToolTip] = useState('');
  const { authData } = useContext(AppContext);

  const customToolTip = (e: React.MouseEvent<HTMLSpanElement>, description: DescriptionType | undefined, indx: number) => {
    if (
      !(e.target as HTMLButtonElement).classList.contains('edit-header') &&
      document.querySelector('.overall-access-audit-table') === undefined &&
      description === undefined
    )
      return;

    customToolTipRemoved(); // Remove all the existing tooltip, duplicate not allowed
    let nonClickableGrpsLen = document.querySelectorAll('.overall-access-audit-table th:not(.grp-clickable)').length;
    const tooltipY = document.querySelector('.table-container.audit-container')?.getBoundingClientRect().y;
    let isDescriptionValid = description?.description !== null && description?.description !== undefined && description?.description !== '' ? true : false;
    let isPeopleValid = description?.people_functionality !== null && description?.people_functionality !== undefined ? true : false;
    let isPipelineValid = description?.pipeline_functionality !== null && description?.pipeline_functionality !== undefined ? true : false;
    let isFinanceValid = description?.finance_functionality !== null && description?.finance_functionality !== undefined ? true : false;

    let htmlTemplate = `<div class="tooltip-container" style="left:${e.pageX - 85}px; top:${Number(tooltipY) - 150}px" ><div class="tooltip-content">
    ${isDescriptionValid ? '<span class="tooltip-description"><span>Description:</span>' + description?.description + '</span>' : ''}
      ${isPeopleValid ? '<span class="tooltip-description"><span>People:</span>' + description?.people_functionality + '</span>' : ''}
      ${isPipelineValid ? '<span class="tooltip-description"><span>Pipeline:</span>' + description?.pipeline_functionality + '</span>' : ''}
      ${isFinanceValid ? '<span class="tooltip-description"><span>Finance:</span>' + description?.finance_functionality + '</span>' : ''}
      ${isDescriptionValid || isPeopleValid || isPipelineValid || isFinanceValid ? '' : '<span class="tooltip-description">No Information Available</span>'}
      </div>
      ${!authData.adminAuthorization.userAuditView ? '<button class="tooltip-update-btn">Add / Update Description</button>' : ''}
      </div>`;

    document.querySelectorAll('.overall-access-audit-table th.grp-clickable')[indx - nonClickableGrpsLen]?.insertAdjacentHTML('beforeend', htmlTemplate);

    document.querySelector('.tooltip-container')?.addEventListener('mouseleave', customToolTipRemoved);

    document.querySelectorAll('.tooltip-container button.tooltip-update-btn')?.forEach(el => el.addEventListener('click', (e: Event) => descriptionUpdate(e)));
  };

  const descriptionUpdate = (e: Event) => {
    setCurrentToolTip((e.target as HTMLElement).closest('.grp-clickable')?.getElementsByClassName('adgrp-name').item(0)?.textContent || '');
    setShowDescriptionModal(true);
  };

  const customToolTipRemoved = () => {
    document.querySelectorAll('.tooltip-container')?.forEach(el => el.remove());
  };

  const hideToolTip = (e: any) => {
    //Above type is any to adjust the type
    if (
      (e.target as HTMLButtonElement).classList.contains('tooltip-container') ||
      (e.target as HTMLButtonElement).classList.contains('tooltip-update-btn') ||
      (e.target as HTMLButtonElement).classList.contains('tooltip-description')
    ) {
      return;
    } else {
      customToolTipRemoved();
    }
  };

  const showComponentDescription = () => {
    setShowComponentDescriptionModal(true);
    //console.log(props.adGroupColData);
    const compDescription: ComponentParamType[] = props.adGroupTableData.map((obj: any) => {
      let subComp = obj['sub-component'].toLowerCase() !== 'n/a' ? obj['sub-component'] : '';
      return {
        page: obj.page,
        tile: obj.tile,
        subComp: subComp,
        description: obj.description,
        id: obj.page + '-#' + obj.tile + '-#' + subComp,
      };
    });
    if (compDescription.length > 0) {
      setComponentDescriptionData(compDescription);
    } else {
      toast.error('No Component Description Available');
    }
  };

  useEffect(() => {
    window.addEventListener('click', hideToolTip);

    return () => {
      window.removeEventListener('click', hideToolTip);
    };
  }, []);

  return (
    <div className="overall-access-audit-table">
      {props.adGroupTableData.length === 0 ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <table className="table-bordered audit-table" onScroll={hideToolTip}>
          <thead>
            <tr>
              {props.adGroupColData.map((data: TableHeaderType, indx: number) => {
                if (data.accessor === 'page') {
                  return (
                    <th key={indx} className="fixed-cols fixed-page">
                      {data.Header}
                    </th>
                  );
                } else if (data.accessor === 'component_hierarchy') {
                  return (
                    <th key={indx} className="fixed-cols fixed-component">
                      {data.Header}
                    </th>
                  );
                } else if (data.accessor === 'description') {
                  return (
                    <th key={indx} className="fixed-cols fixed-description">
                      <>
                        {!authData.adminAuthorization.userAuditView ? (
                          <span className="edit-header" onClick={showComponentDescription}>
                            <PlusIcon fill={'var(--ops-primary-blue)'} width={18} height={18} />
                          </span>
                        ) : (
                          ''
                        )}
                      </>
                      <span>{data.Header}</span>
                    </th>
                  );
                } else {
                  return (
                    <th key={indx} className="grp-clickable" data-testid={data.Header}>
                      <span onClick={() => props.rtsHeader(data.Header)} className="adgrp-name">
                        {data.Header}
                      </span>
                      <span className="edit-header" onMouseEnter={e => customToolTip(e, adMap.get(data.Header), indx)}>
                        <InfoIcon fill={'var(--ops-primary-blue)'} width={18} height={18} />
                      </span>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {props.userAuditTableHeader.length > 0 &&
              props.adGroupTableData.map((data: any, indx: any) => {
                return (
                  <tr key={indx}>
                    {props.adGroupColData.map((colData: any, colIndx: any) => {
                      if (colData.accessor === 'page') {
                        return (
                          <td key={indx + colIndx} className="fixed-cols fixed-page">
                            {data[colData.accessor]}
                          </td>
                        );
                      } else if (colData.accessor === 'description') {
                        return (
                          <td key={indx + colIndx} className="fixed-cols fixed-description">
                            {data[colData.accessor]}
                          </td>
                        );
                      } else if (colData.accessor === 'component_hierarchy') {
                        if (data['sub-component'] !== 'N/A') {
                          return (
                            <td key={indx + colIndx} className="fixed-cols fixed-component">
                              {data['tile']} : <i> {data['sub-component']} </i>
                            </td>
                          );
                        } else {
                          return (
                            <td key={indx + colIndx} className="fixed-cols fixed-component">
                              {data['tile']}
                            </td>
                          );
                        }
                      } else if (data[colData.accessor] === 'Y') {
                        let title = `Ad Group: ${props.userAuditTableHeader[colIndx]?.Header.toUpperCase()} have access for Tile: ${data[
                          'tile'
                        ].toUpperCase()} in Page: ${data['page'].toUpperCase()}`;
                        return (
                          <td key={indx + colIndx} title={title}>
                            <div className="group group-present"></div>
                          </td>
                        );
                      } else {
                        let title = `Ad Group: ${props.userAuditTableHeader[colIndx]?.Header.toUpperCase()} do not have access for Tile: ${data[
                          'tile'
                        ].toUpperCase()} in Page: ${data['page'].toUpperCase()}`;
                        return (
                          <td key={indx + colIndx} title={title}>
                            <div className="group group-absent"></div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {showDescriptionModal && adMap.size > 0 ? (
        <DescriptionModal
          opened={showDescriptionModal}
          descriptionModalClosed={(val: boolean) => setShowDescriptionModal(val)}
          descriptionData={adMap}
          currentToolTip={currentToolTip}
          setDescriptionData={(val: []) => props.setDescriptionData(val)}
        />
      ) : null}
      {showComponentDescriptionModal ? (
        <ComponentDescriptionModal
          opened={showComponentDescriptionModal}
          descriptionModalClosed={(val: boolean) => setShowComponentDescriptionModal(val)}
          descriptionData={componentDescriptionData}
          triggerComponentDescriptionUpdate={props.triggerComponentDescriptionUpdate}
          setTriggerComponetUpdate={(val: Boolean) => props.setTriggerComponetUpdate(val)}
        />
      ) : null}
    </div>
  );
};

export default OverAllAccessTable;
