import {
	Alert,
	Button,
	Flex,
	Modal,
	Tabs,
} from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { manageNotificationRequest } from '../../../services/manage-notifications-request';
import { fetchRequestLogModalData } from '../../../services/request-log-modal-data';
import { AppContext } from '../../../state/app-context';
import {
	RequestLogModalData,
	RequestLogModalSettings,
	SubscriptionAction,
	RetriggerNotificationRequestData,
} from '../../../types';
import UnexpectedError from '../../errors/unexpected-error';
import NotificationsLoader from '../../reusable/notifications-loader';
import AdUsersModal from './ad-users-modal';
import './index.scss';
import RequestLogModalPreview from './request-log-modal-preview';
import RequestLogModalRecipients from './request-log-modal-recipients';
import {
	retriggerDisabledMessage,
	retriggerDisabledExpiredMessage,
} from '../../../constants';

interface RequestLogModalProps {
	opened: boolean;
	requestLogModalSetting: RequestLogModalSettings;
	requestToken: string | undefined;
	approvalStatus: string;
	onModalDismiss: (resetRoute?: boolean) => void;
	openValidationModal: (
		action: SubscriptionAction,
		workstream?: string,
		subscriberName?: string,
		notificationName?: string,
		approvalStatus?: string
	) => void;
	onCancelAdUsersModal: (modalState: RequestLogModalSettings) => void;
	onRetrigger: (retriggerRequestData: RetriggerNotificationRequestData) => void;
	disablePolling: (value: boolean) => void;
}

const RequestLogModal = ({
	opened,
	requestLogModalSetting,
	requestToken,
	approvalStatus,
	onModalDismiss,
	openValidationModal,
	onCancelAdUsersModal,
	onRetrigger,
	disablePolling,
}: RequestLogModalProps) => {
	const { userFullname, userId, setTableContentLoader } =
		useContext(AppContext);
	const [requestModalData, setRequestModalData] = useState<
		RequestLogModalData | undefined
	>(undefined);
	const [openAdUsersModal, setOpenAdUsersModal] = useState<boolean>(false);
	const [selectedADGroup, setSelectedADGroup] = useState<string>('');
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isError, setIsError] = useState<{ status: boolean; message: string }>({
		status: false,
		message: '',
	});

	const navigate = useNavigate();

	const handleOpenAdUsersModal = useCallback(
		(adGroup: string) => {
			setOpenAdUsersModal(true);
			disablePolling(true);
			onModalDismiss(false);
			setSelectedADGroup(adGroup);
		},
		[disablePolling, onModalDismiss]
	);

	const defaultTabs = useMemo(() => {
		return requestModalData
			? [
					{
						children: <RequestLogModalPreview requestData={requestModalData} />,
						key: 'default',
						label: <span>Preview</span>,
					},
					{
						children: (
							<RequestLogModalRecipients
								recipientsData={requestModalData?.recipients}
								deliveryStatus={requestModalData?.delivery_status}
								onOpenAdUsersModal={handleOpenAdUsersModal}
								requestLogModalSetting={requestLogModalSetting}
							/>
						),
						key: 'secondary',
						label: <span>Recipients</span>,
					},
			  ]
			: [];
	}, [requestModalData, requestLogModalSetting, handleOpenAdUsersModal]);

	const fetchRequestLogModalDatafn = useCallback(() => {
		fetchRequestLogModalData({}, requestToken ?? '')
			.then((response) => {
				setRequestModalData(response);
				setIsError({ status: false, message: '' });
			})
			.catch((error) => {
				console.log(error);
				setRequestModalData(undefined);
				setIsError({ status: true, message: error.message });
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [requestToken]);

	const onAdUsersModalDismiss = useCallback(() => {
		setOpenAdUsersModal(false);
		disablePolling(false);
	}, [disablePolling]);

	// handle the flow for fetching the approval and deny APIs
	const handleManageNotificationRequest = useCallback(
		(action: 'approve' | 'deny') => {
			// set global loader upon calling the API
			setTableContentLoader(true);
			// open the validation modal, which will be displaying a loader at first due to the tableContentLoader context state
			openValidationModal(
				action === 'approve'
					? SubscriptionAction.APPROVE_SUBSCRIBER
					: SubscriptionAction.DENY_SUBSCRIBER,
				undefined,
				undefined,
				undefined,
				requestModalData?.approval_status === 'Ready for Review'
					? 'Review'
					: requestModalData?.approval_status
			);
			// dismiss current Request Log Modal
			onModalDismiss();
			// hit the approve / deny request
			manageNotificationRequest(action, requestToken ?? '')
				.then(() => {})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					// once the Promise concludes set the global loader to false
					setTableContentLoader(false);
				});
		},
		[
			onModalDismiss,
			openValidationModal,
			requestModalData?.approval_status,
			requestToken,
			setTableContentLoader,
		]
	);

	useEffect(() => {
		if (requestToken && opened) {
			setIsFetching(true);
			setIsError({ status: false, message: '' });
			fetchRequestLogModalDatafn();
		}
	}, [requestToken, opened, fetchRequestLogModalDatafn]);

	const requestLogModalFooter =
		requestModalData?.approval_status === 'Ready for Review' ||
		requestModalData?.approval_status === 'Expired' ? (
			<Flex
				justify="flex-end"
				align="center"
				gap={12}
				className="notifications-request-log-modal-footer"
			>
				<Button
					type={
						requestModalData?.approval_status === 'Expired'
							? 'primary'
							: 'secondary'
					}
					text={
						requestModalData?.approval_status === 'Expired'
							? 'RESEND'
							: 'DENY & RESEND'
					}
					onClick={() => {
						onRetrigger({
							event_id: requestModalData?.event_id ?? '',
							event_log_id: requestModalData?.event_log_id ?? '',
							requester_id: userId,
							requester_name: userFullname,
						});
						onModalDismiss(false);
						if (requestModalData?.approval_status === 'Expired') {
							openValidationModal(SubscriptionAction?.WARN_RESEND_REQUEST);
						} else {
							openValidationModal(
								SubscriptionAction?.WARN_DENY_AND_RESEND_REQUEST
							);
						}
					}}
					style={{ flex: '1' }}
					disabled={
						!requestModalData?.is_retrigger_valid ||
						requestModalData?.is_self_managed ||
						requestModalData?.digest_notification ||
						!requestModalData?.modal_access ||
						requestModalData?.approval_status === 'Expired'
					}
				/>
				{requestModalData?.approval_status === 'Ready for Review' && (
					<>
						<Button
							text="DENY"
							onClick={() => {
								handleManageNotificationRequest('deny');
							}}
							type="secondary"
							style={{ flex: '1' }}
							disabled={
								!requestModalData?.is_approval_valid ||
								!requestModalData?.modal_access
							}
						/>
						<Button
							text="APPROVE"
							onClick={() => {
								handleManageNotificationRequest('approve');
							}}
							type="primary"
							style={{ flex: '1' }}
							disabled={
								!requestModalData?.is_approval_valid ||
								!requestModalData?.modal_access
							}
						/>
					</>
				)}
			</Flex>
		) : null;

	useEffect(() => {
		if (requestModalData) {
			if (!requestModalData.modal_access) {
				navigate('/subscriptions/unauthorized');
			}
		}
	}, [requestModalData, navigate]);

	return (
		<>
			<Modal
				className="notifications-request-log-modal"
				classNames={{
					body: `notifications-request-log-modal-body ${
						isFetching
							? 'large'
							: requestModalData?.approval_status === 'Ready for Review' ||
							  requestModalData?.approval_status === 'Expired'
							? 'small'
							: 'large'
					}`,
				}}
				open={opened}
				onCancel={onModalDismiss}
				title={`Notification ${
					!isFetching &&
					!isError.status &&
					requestModalData &&
					requestModalData?.approval_status
						? requestModalData?.approval_status === 'Ready for Review'
							? '- Review'
							: requestModalData?.approval_status === 'None Required'
							? '- No Approval Required'
							: '- ' + requestModalData?.approval_status
						: approvalStatus.includes('Ready for Review')
						? '- Review'
						: approvalStatus === 'None Required'
						? '- No Approval Required'
						: '- ' + approvalStatus
				}`}
				footer={
					isFetching || isError.status ? undefined : requestLogModalFooter
				}
				fixedHeight={true}
				height={
					isFetching
						? 604
						: requestModalData?.approval_status === 'Ready for Review' ||
						  requestModalData?.approval_status === 'Expired'
						? 543
						: 604
				}
			>
				{isFetching && !isError.status && (
					<NotificationsLoader type="skeleton" style={{ marginTop: '16px' }} />
				)}
				{!isFetching && isError.status && (
					<UnexpectedError
						errorDetails={isError.message}
						style={{
							marginTop: '40px',
							width: '322px',
							marginInline: 'auto',
						}}
					/>
				)}
				{!isFetching && !isError.status && (
					<Flex vertical>
						{(requestModalData?.is_self_managed ||
							requestModalData?.digest_notification ||
							requestModalData?.approval_status === 'Expired') && (
							<Alert
								type="info"
								content={
									requestModalData?.approval_status === 'Expired'
										? retriggerDisabledExpiredMessage
										: retriggerDisabledMessage
								}
								className="notifications-retrigger-disable-alert"
							/>
						)}
						<Tabs
							items={isFetching || isError.status ? undefined : defaultTabs}
							tabBarStyle={{ width: '152px' }}
							defaultActiveKey={requestLogModalSetting}
						/>
					</Flex>
				)}
			</Modal>
			<AdUsersModal
				isOpen={openAdUsersModal}
				requestStatus={requestModalData?.approval_status ?? ''}
				adGroup={selectedADGroup}
				onAdUsersModalDismiss={onAdUsersModalDismiss}
				onCancelAdUsersModal={onCancelAdUsersModal}
			/>
		</>
	);
};

export default RequestLogModal;
