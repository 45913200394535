import React, { ReactNode, useMemo, useRef } from 'react';
import { useState, useEffect, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, rowSpanning, filterMembers, camelToHyphen } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import { timeToHrMinSecond } from '../utility';
import { AppContext } from '../../../contexts/app-context';
import { RowSpanType, MetricProps } from '../usage-metric-interface';
import useRenderSpanning from '../../../hooks/use-render-spanning';
import AgGridTable from '../../../utility/agGridTable';
import { FileDownloadIcon, ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';


function getPathFromUrl(url: string): string {
  const urlObject = new URL(url);
  return urlObject.pathname;
}

const UnauthorizedTracking = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  // const tableData = useRenderSpanning(usageTrackingData, columns, props.date, 'unauthorizedtracking', false);
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
      let filteredData = dataArray.filter((obj: any) => obj.userid === userFilter.userId);

      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
        setUsageTrackingData(filteredData);
        return;
      }
      alignFilterData(filteredData);
    } else {
      filterGroupValue(usageTrackingOriginal);
      setTableMsg('No Data Available');
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById('unauthorized-tracking')?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById('unauthorized-tracking')?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = usageTrackingOriginal;
    }

    const filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    if (filteredData.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    }
    setUsageTrackingData(rowSpanning(filteredData));
  };

  const alignFilterData = (dataArray: any) => {
    let filteredData = dataArray;
    if (filteredData.length === 0) {
      setTableMsg(`No Data Available`);
    }

    setUsageTrackingData(rowSpanning(filteredData));
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '6',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Unauthorized Tracking: Data not available!!');
          } else {
            res.data.data.user_metric = res.data.data.user_metric.map((obj: any) => {
              return Object.assign(obj, {
                timespent: timeToHrMinSecond(parseInt(obj.timespent)),
              });
            });
            res.data.data.user_metric = res.data.data.user_metric.map((metric: any) => {
              metric.from_url = metric.from_url === '' ? '' : getPathFromUrl(metric.from_url);
              return metric;
            });

            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric); //Filter By User Group & SetUsageTrackingData
            //setUsageTrackingData(res.data.data.user_metric);
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        props.setClearData(false);
        toast.error('Unauthorized Tracking: Failed to load!!');
      }
    } catch (error) {
      console.log(error, columns);
      props.setClearData(false);
      toast.error('Unauthorized Tracking: Something Went Wrong!!');
    }
    setLoading(false);
  };


  return (
    <div className="usage-metric unauthorized-tracking">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters ">
          <FileDownloadIcon height={28} width={28} onClick={() => {
            setTriggerDownload(true);
          }} />
          {!showExpandOrClose ? (
            <span
              onClick={() => {
                setShowExpandOrClose(true);
              }}
              className="expand-collapse-icon"
            >
              <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
            </span>
          ) : (
            <span
              onClick={() => {
                setShowExpandOrClose(false);
              }}
            >
              <CloseIcon className="expand-collapse-icon" />
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <AgGridTable
              rowData={usageTrackingData}
              columnDefs={columns}
              matamoDate={props.date.matamoDate}
              matamoType={props.date.matamoType}
              triggerdownload={triggerDownload}
              setTriggerDownload={setTriggerDownload}
              onExpandableClosed={() => setShowExpandOrClose(false)}
              expandable={showExpandOrClose}
              tableName="Unauthorized Tracking"
            />
          )}
        </div>
      )}
    </div>
  );
};
export default UnauthorizedTracking;
