import {
	Alert,
	CheckboxGroup,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { FetchNotificationResponse } from '../../../../types';

interface PreviewModalChannelProps {
	notification: FetchNotificationResponse;
	checkedChannels: string[];
	subscriptionError: boolean;
	setSubscriptionError: (value: boolean) => void;
	onChannelChange: (checkedChannels: string[]) => void;
}

const PreviewModalChannel = ({
	notification,
	checkedChannels,
	subscriptionError,
	setSubscriptionError,
	onChannelChange,
}: PreviewModalChannelProps) => {
	const isADGroup = notification?.is_ad_group;

	const { token } = theme;

	return (
		<Flex vertical gap={8} style={{ marginTop: '11px' }}>
			<Text strong className="notifications-preview-modal-channel">
				Channel
			</Text>
			{subscriptionError ? (
				<div id="notifications-preview-modal-error-message">
					<Alert
						type="error"
						content="Channel selection required."
						closable={false}
					/>
				</div>
			) : null}
			<Flex
				className="notifications-preview-modal-channel-detail"
				align="center"
				wrap="wrap"
			>
				<Text
					style={{
						color: token?.colorTextLabel,
						marginRight: !isADGroup ? '33px' : undefined,
					}}
				>
					{`You receive notifications through${!isADGroup && ':'}`}
				</Text>
				{isADGroup ? (
					<Text style={{ color: token?.colorTextLabel, marginRight: '33px' }}>
						&nbsp;
						{notification.subscribed_channels.length > 0
							? notification.subscribed_channels
									.map<React.ReactNode>((channel, index) => {
										if (
											channel === 'channel_ms_teams_chat' ||
											channel === 'channel_ms_teams_channel' ||
											channel === 'ms_teams_chat'
										) {
											return <Text key={index}>Teams</Text>;
										} else if (
											channel === 'channel_email' ||
											channel === 'channel_delivery_email'
										) {
											return <Text key={index}>Email</Text>;
										} else {
											return <Text key={index}>SMS</Text>;
										}
									})
									.reduce((prev, curr) => [prev, ' and ', curr])
							: 'N/A'}
						.
					</Text>
				) : (
					<CheckboxGroup
						options={notification?.channel_id.map((channel) => {
							return {
								label: channel.includes('teams')
									? 'MS Teams'
									: channel.includes('email')
									? 'Email'
									: 'SMS',
								value: channel,
							};
						})}
						value={checkedChannels}
						onChange={(selectedChannels) => {
							onChannelChange(selectedChannels as string[]);
							if (subscriptionError) {
								setSubscriptionError(false);
							}
						}}
					></CheckboxGroup>
				)}
			</Flex>
		</Flex>
	);
};

export default PreviewModalChannel;
