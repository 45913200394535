import React, { useMemo } from 'react';
import { useState, useEffect, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, rowSpanning, filterMembers, camelToHyphen } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import { timeToHrMinSecond } from '../utility';
import { AppContext } from '../../../contexts/app-context';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import { FileDownloadIcon } from '@opsdti-global-component-library/amgen-design-system';
import AgGridTable from '../../../utility/agGridTable';
import { ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';

const AvgTimeSpent = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    }
    setUsageTrackingData(rowSpanning(filteredData));
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '3',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Average Time Spent: Data not available!!');
          } else {
            //Below is for time formating
            res.data.data.user_metric = res.data.data.user_metric.map((obj: { average_timespent: string }) => {
              return Object.assign(obj, {
                average_timespent: timeToHrMinSecond(parseInt(obj.average_timespent)),
              });
            });

            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Average Time Spent: Failed to load!!');
        props.setClearData(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Average Time Spent: Something Went Wrong!!');
      props.setClearData(false);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById(camelToHyphen('averageTimeSpentByUserPerPage'))?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById(camelToHyphen('averageTimeSpentByUserPerPage'))?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  return (
    <div className="usage-metric avg-time-spent">

      <div className='metric-header'>
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters ">
          <FileDownloadIcon height={28} width={28} onClick={() => {
            setTriggerDownload(true);
          }} />
          {!showExpandOrClose ? (
            <span
              onClick={() => {
                setShowExpandOrClose(true);
              }}
              className="expand-collapse-icon"
            >
              <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
            </span>
          ) : (
            <span
              onClick={() => {
                setShowExpandOrClose(false);
              }}
            >
              <CloseIcon className="expand-collapse-icon" />
            </span>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          <AgGridTable
            rowData={usageTrackingData}
            columnDefs={columns}
            matamoDate={props.date.matamoDate}
            matamoType={props.date.matamoType}
            triggerdownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
            onExpandableClosed={() => setShowExpandOrClose(false)}
            expandable={showExpandOrClose}
            tableName="Average Time Spent"
          />
        </div>
      )}
    </div>
  );
};
export default AvgTimeSpent;
