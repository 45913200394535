import React, { useState, useContext, useEffect } from 'react';
import './index.scss';
import { BarChart, Bar, LabelList, YAxis, ResponsiveContainer, XAxis, Cell, CartesianGrid, Legend, Tooltip } from 'recharts';
import { AppContext } from '../../contexts/app-context';

type Props = {
  data?: any;
  top: number;
  chartType: string;
  right: number;
  left: number;
  bottom: number;
  clearFilter?: boolean;
  primaryColor?: string;
  highlightedColor?: string;
};

interface CustomToolTipProps {
  active: Boolean;
  payload: {
    payload: any;
    visitcount: any;
  }[];
  label: string;
}

const BarGraph: React.FC<Props> = (props: Props) => {
  const { top, right, left, bottom, chartType, clearFilter } = props;
  let { data } = props;
  const { userFilter, setUserFilter } = useContext(AppContext);
  const [filterBar, setFilterBar] = useState(null);
  let respData: any = [];
  let graphHeight = '';
  const sensingWorkstreams = [
    'admin',
    'external',
    'intelligence',
    'home',
    'pipeline',
    'supply',
    'finance',
    'brand',
    'people',
    'prioritized-agenda',
    'curation',
    'manufacturing',
    'notification',
    'subscriptions',
    'about',
  ]; //Add allowed workstream

  const workstreamWithSubPages: string[] = [];
  //data.filter(obj => obj.fullname.startsWith('external')).reduce((acc,val) => acc=acc+Number(val.visitcount),0)

  sensingWorkstreams.map(workstream => {
    if (data.filter((obj: any) => obj.fullname.startsWith(workstream)).length > 0) {
      workstreamWithSubPages.push(workstream);
    }
  });

  const convertUpperCase = (data: string) => {
    return data.replace(/(^\w{1})|(\s+\w{1})/g, data => data.toUpperCase());
  };

  if (chartType === 'page-usage') {
    //filter the data based on the workstream, remove unwanted data
    //data = data.filter((obj: any) => sensingWorkstreams.includes(obj.fullname.indexOf('/') > -1 ? obj.fullname.split('/')[0] : obj.fullname));

    //combine the data based on the workstream with multiple sub-pages & aggregate count
    for (let i = 0; i < sensingWorkstreams.length; i++) {
      let item = data.filter((obj: any) => obj.fullname.startsWith(sensingWorkstreams[i]));
      if (item.length > 1) {
        let fullname = item && item[0].fullname.indexOf('/') > -1 ? item[0].fullname.split('/')[0] : item[0].fullname;
        let workstreamTotal = item.reduce((acc: any, val: any) => (acc = acc + Number(val.visitcount)), 0);
        respData.push({
          fullname: fullname,
          visitcount: workstreamTotal,
          name: fullname && convertUpperCase(fullname.replace('-', ' ').toUpperCase()),
        });
      } else if (item.length > 0 && respData.filter((obj: any) => obj.fullname === item[0].fullname).length === 0) {
        let fullname = item[0].fullname.indexOf('/') > -1 ? item[0].fullname.split('/')[0] : item[0].fullname;
        respData.push({
          fullname: fullname,
          visitcount: item[0].visitcount,
          name: fullname && convertUpperCase(fullname.replace('-', ' ').toUpperCase()),
        });
      }
    }

    graphHeight = respData.length < 7 ? 12.5 + 'em' : respData.length * 2.3 + 'em';
    respData.sort((a: any, b: any) => (a.visitcount < b.visitcount ? 1 : -1));
  } else {
    graphHeight = '98%';
    data.map((ele: any) =>
      respData.push({
        name: ele && ele.fullname && convertUpperCase(ele.fullname.replace('-', ' ').toUpperCase()),
        visitcount: ele.visitcount,
        userId: ele.userid,
      }),
    );
  }

  useEffect(() => {
    if (clearFilter) {
      setFilterBar(null);
    }
  }, [clearFilter]);

  const handleBarClick = (e: any) => {
    if (chartType === 'kpi') {
      setFilterBar(e.name.toLowerCase());
      if (e.userId !== userFilter.userId) {
        let filterValue = { userId: e.userId, filter: true, fullname: e.name };
        setUserFilter(filterValue);
      } else {
        let filterValue = { userId: 'All', filter: false, fullname: e.name };
        setUserFilter(filterValue);
        setFilterBar(null);
      }
    }
  };

  const CustomTooltip = (props: CustomToolTipProps) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length && chartType === 'page-usage' && workstreamWithSubPages.length > 0) {
      let labelmatchWorkstream = label.split('/').length > 0 ? label.split('/')[0].toLowerCase() : label.toLowerCase();
      if (labelmatchWorkstream === 'prioritized agenda') {
        labelmatchWorkstream = 'prioritized-agenda'; //Its an exception
      }
      let workstreamPage = workstreamWithSubPages.filter((obj: any) => obj.startsWith(labelmatchWorkstream));
      return (
        <div className="custom-tooltip">
          {/*<p className="label">{`${label} : ${payload[0].payload.visitcount}`}</p>*/}
          <p className={`${chartType === 'page-usage' ? 'page-usage-label' : 'label'}`}>
            <span key="label">{`${label}`}</span>
            <>
              {label.toLowerCase() === 'prioritized agenda' ? (
                <a href={`${window.location.origin + '/prioritized-agenda/key-events/1'}`} target="_parent">
                  {' '}
                  {window.location.origin + '/prioritized-agenda/key-events/1'}{' '}
                </a>
              ) : null}
            </>{' '}
          </p>
          <>
            {workstreamPage.length > 0
              ? data
                  .filter((obj: any) => obj.fullname.startsWith(workstreamPage))
                  .map((obj: any, indx: string) => {
                    return (
                      <p key={indx} className="label-list">
                        {obj.fullname.indexOf('/') > -1 ? obj.fullname.split('/')[1] : obj.fullname} : {obj.visitcount}
                      </p>
                    );
                  })
              : null}
          </>
        </div>
      );
    }

    return null;
  };

  if (data.length === 0) {
    return <div className="no-data-available">No Data Available</div>;
  } else {
    return (
      <>
        <div className="adm-barchart-container" style={{ height: `${graphHeight}` }}>
          <>
            <ResponsiveContainer width="100%">
              <BarChart barCategoryGap={40} barSize={18} data={respData} layout="vertical" margin={{ top: top, right: right, left: left, bottom: bottom }}>
                <CartesianGrid horizontal={false} stroke="#E9E9E9" />
                <XAxis
                  type="number"
                  axisLine={false}
                  tickLine={false}
                  style={{
                    fontSize: '10px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    lineHeight: '14px',
                    color: '#9C9C9C',
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  type="category"
                  width={100}
                  dx={-5}
                  dy={0}
                  padding={{ top: 5, bottom: 5 }}
                  dataKey="name"
                  style={{
                    fontSize: '10px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    lineHeight: '14px',
                    color: '#9C9C9C',
                    cursor: 'pointer',
                  }}
                  // onClick={handleGraphLabelClick}
                />

                {/* <Tooltip cursor={false}/>  */}
                <>{chartType === 'page-usage' ? <Tooltip content={<CustomTooltip active={true} payload={[]} label={''} />} offset={-10} /> : null}</>
                <Bar dataKey="visitcount" onClick={handleBarClick} isAnimationActive={false} style={{ cursor: ' pointer' }}>
                  {data.map((entry: any, index: any) => (
                    <Cell
                      fill={
                        filterBar === entry.fullname || filterBar === entry.fullname.toLowerCase().replaceAll('-', ' ')
                          ? props.highlightedColor
                          : props.primaryColor !== undefined
                          ? props.primaryColor
                          : '#0063c3'
                      }
                      key={index}
                    />
                  ))}
                  <LabelList
                    dataKey="visitcount"
                    position="right"
                    style={{
                      fontSize: '10px',
                      fill: '#585858',
                      fontFamily: 'Inter',
                      fontWeight: '700',
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        </div>
      </>
    );
  }
};

export default BarGraph;
