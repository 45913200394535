import { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, filterMembers, camelToHyphen } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { RowSpanType, UserInPauseProps } from '../usage-metric-interface';
import AgGridTable from '../../../utility/agGridTable';
import { FileDownloadIcon } from '@opsdti-global-component-library/amgen-design-system';
import { ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';

const UserInPause = (props: UserInPauseProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    setUsageTrackingData([]);
    setUsageTrackingOriginal([]);
    getUsageTrackingData();
  }, []);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = usageTrackingOriginal;
    }

    const filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    if (filteredData.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    } else {
      setTableMsg(`No Data Available`);
    }
    setUsageTrackingData(filteredData);
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '10',
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Paused Activity By User: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: any, b: any) => {
              if (a.visitcount > b.visitcount) {
                return -1;
              } else if (a.visitcount < b.visitcount) {
                return 1;
              } else {
                return 0;
              }
            });
            filterGroupValue(res.data.data.user_metric); //Filter By User Group & SetUsageTrackingData
            //setUsageTrackingData(res.data.data.user_metric);
            setUsageTrackingOriginal(res.data.data.user_metric);
          }
        }
      } else {
        console.log(res);
        toast.error('Paused Activity By User: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Paused Activity By User: Something Went Wrong!!');
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById(camelToHyphen('pausedActivityByUser'))?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById(camelToHyphen('pausedActivityByUser'))?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  return (
    <div className="usage-metric paused-user-activity">
      <div className="metric-header">
        <div>
          <label className="form-label">Since Past 30 Days</label>
        </div>
        <div className="filters ">
          <FileDownloadIcon height={28} width={28} onClick={() => {
            setTriggerDownload(true);
          }} />
          {!showExpandOrClose ? (
            <span
              onClick={() => {
                setShowExpandOrClose(true);
              }}
              className="expand-collapse-icon"
            >
              <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
            </span>
          ) : (
            <span
              onClick={() => {
                setShowExpandOrClose(false);
              }}
            >
              <CloseIcon className="expand-collapse-icon" />
            </span>
          )}
        </div>
        <div className="non-user-group-note">
          <p>* Users who have not visited Sensing in the last 30 days</p>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <AgGridTable
              rowData={usageTrackingData}
              columnDefs={columns}
              matamoDate=""
              matamoType=""
              triggerdownload={triggerDownload}
              setTriggerDownload={setTriggerDownload}
              onExpandableClosed={() => setShowExpandOrClose(false)}
              expandable={showExpandOrClose}
              tableName="Paused Activity By User"
            />
          )}
        </div>
      )}
    </div>
  );
};
export default UserInPause;
