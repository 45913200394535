import './index.scss';
import { useEffect } from 'react';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';
import teamsIcon from '../../../assets/images/teams-icon.png';

interface TeamsTemplateProps {
	templateContent: string;
	isTemplate: boolean;
}

const TeamsTemplate = ({ templateContent, isTemplate }: TeamsTemplateProps) => {
	useEffect(() => {
		if (templateContent) {
			const parser = new DOMParser();
			const doc = parser.parseFromString(templateContent || '', 'text/html');

			const element = document.getElementById(
				'notifications-teams-example-content'
			);
			if (element) {
				element.innerHTML = doc.querySelector('body')?.innerHTML || '';
			}
		}
	}, [templateContent]);

	return (
		<Flex className="notifications-teams-template">
			<div className="notifications-teams-logo">
				<img src={teamsIcon} />
			</div>
			<div
				id="notifications-teams-example-content"
				className={`notifications-teams-example-content ${
					isTemplate ? 'disable-links' : ''
				}`}
			></div>
		</Flex>
	);
};

export default TeamsTemplate;
