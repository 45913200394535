import {
	Tabs,
	Title,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import EmailTemplate from '../../notification-templates/email-template';
import SmsTemplate from '../../notification-templates/sms-template';
import TeamsTemplate from '../../notification-templates/teams-template';
import './index.scss';

interface NotificationPreviewProps {
	previewDetails: {
		channel: string;
		templateContent: string;
	}[];
	title: string;
	style?: React.CSSProperties;
	isTemplate?: boolean;
}

const NotificationPreview = ({
	previewDetails,
	title,
	style,
	isTemplate = true,
}: NotificationPreviewProps) => {
	const { token } = theme;
	const defaultTemplateContent =
		'<!DOCTYPE html><html><head></head><body><p><b>No example available</b></p></body>';

	const createTemplateTabs = (channel: string, templateContent: string) => {
		const template = templateContent ? templateContent : defaultTemplateContent;

		return {
			children:
				channel === 'MS Teams' ? (
					<TeamsTemplate templateContent={template} isTemplate={isTemplate} />
				) : channel === 'Email' ? (
					<EmailTemplate templateContent={template} isTemplate={isTemplate} />
				) : (
					<SmsTemplate templateContent={template} isTemplate={isTemplate} />
				),
			key: channel.toLowerCase(),
			label: <span>{channel}</span>,
		};
	};

	const channelsTabs = previewDetails.map((preview) =>
		createTemplateTabs(preview.channel, preview.templateContent)
	);

	return (
		<div
			className="notifications-example-box"
			style={{
				border: `1px solid ${token?.colorBorderSecondary}`,
				backgroundColor: `${token?.colorBgLayout}`,
				...style,
			}}
		>
			<Title level={3} className="notifications-example-metric-details">
				{title}
			</Title>
			<Tabs items={channelsTabs} />
		</div>
	);
};

export default NotificationPreview;
