import React, { useState } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import { OpsButton, OpsTypography } from '@gitlab-rtsensing/component-library';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';

import { IReleaseVersion, IReleaseVersionInitialFormData } from '../release-note-interfaces';

import { apiResponse, validateDateFormat, validateVersionFormat } from '../../../utility/commonMethods';

import ErrorDisplay from '../error-display';
import DatePickerComponent from '../../form-components/date-picker';
import XYZInput from '../../form-components/xyz-input';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';

interface SubmittedReleaseVersion {
  releaseVersionId?: number;
  releaseVersionNumber: string;
  releaseVersionDate: string;
}

interface ReleaseVersionFormProps {
  closeModal: () => void;
  onReleaseVersionModalClosed: () => void;
  initialFormData: IReleaseVersionInitialFormData;
  fetchReleaseVersions?: () => Promise<void>;
  currentReleaseVersions?: IReleaseVersion[];
}

const ReleaseVersionForm: React.FC<ReleaseVersionFormProps> = ({ closeModal, initialFormData }) => {
  const { releaseVersions: currentReleaseVersions, refetch } = useReleaseNotesContext();
  const [version, setVersion] = useState(initialFormData?.releaseVersionNumber || '');
  const [date, setDate] = useState(initialFormData?.releaseVersionDate || '');
  const [errors, setErrors] = useState({
    version: '',
    date: '',
    form: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleError = (error: unknown, action: string) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Server error:', error.response.status, error.response.data);
        toast.error(`An error occurred while ${action} release note: ${error.response.status}`);
      } else if (error.request) {
        console.error('Network error:', error.request);
        toast.error(`An error occurred while ${action} release note. Please check your network connection.`);
      } else {
        console.error('Error', error.message);
        toast.error(`An error occurred while ${action} release note: ${error.message}`);
      }
    } else {
      console.error(`Error ${action} release note:`, error);
      toast.error(`An error occurred while ${action} release note.`);
    }
  };

  const handleReleaseVersion = async (payload: SubmittedReleaseVersion, action: string, apiEndPoint: string) => {
    try {
      let { data } = await apiResponse('post', apiEndPoint, [], payload);

      if (data) {
        toast.success(`Release note successfully ${action}.`);
        await refetch('get-release-versions');
        closeModal();
      } else {
        toast.error(`An error occurred while ${action} release note.`);
      }
    } catch (error) {
      handleError(error, action);
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let isValid = true;
    let formError = '';
    let versionError = '';
    let dateError = '';

    if (!initialFormData && currentReleaseVersions.find(rv => rv.releaseVersionDate === date && rv.releaseVersionNumber === version)) {
      formError = 'Release version and date already exist';
      isValid = false;
    }

    if (!validateVersionFormat(version)) {
      versionError = 'Invalid version format';
      isValid = false;
    }

    if (!validateDateFormat(date)) {
      dateError = 'Invalid date format';
      isValid = false;
    }

    setErrors({
      version: versionError,
      date: dateError,
      form: formError,
    });

    if (isValid) {
      setIsLoading(true);
      const payload = {
        releaseVersionNumber: version,
        releaseVersionDate: date,
        releaseVersionId: initialFormData?.releaseVersionId,
      };
      const apiEndPoint = initialFormData ? 'update-release-version' : 'create-release-version';
      const action = initialFormData ? 'updated' : 'created';

      await handleReleaseVersion(payload, action, apiEndPoint);

      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spin />
  ) : (
    <form className="release-version-form">
      {errors.form && <ErrorDisplay message={errors.form}></ErrorDisplay>}
      {errors.version && <ErrorDisplay message={errors.version}></ErrorDisplay>}
      {errors.date && <ErrorDisplay message={errors.date}></ErrorDisplay>}
      <div className="release-version-form__input-container">
        <div className="release-version-from__version-input-container">
          <label>
            <OpsTypography variant="label" elementTag="span" children="VERSION NUMBER" />
          </label>

          <XYZInput updateForm={setVersion} initialVersion={initialFormData?.releaseVersionNumber || null} />
        </div>
        <div className="release-version-from__date-input-container">
          <label>
            <OpsTypography variant="label" elementTag="span" children="RELEASE DATE" />
          </label>

          <DatePickerComponent dateString={initialFormData?.releaseVersionDate || ''} onChange={setDate} />
        </div>
      </div>
      <div className="release-version-form__button-container">
        <OpsButton className="release-version-form__cancel-button" label="cancel" type="secondary" onClick={closeModal} />
        <OpsButton
          className="release-version-form__submit-button"
          label={!initialFormData ? 'CREATE RELEASE' : 'UPDATE INFO'}
          type="primary"
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

export default ReleaseVersionForm;
