import React from 'react';
import './index.scss';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';

interface PageLayoutProps {
	children: JSX.Element;
}
const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
	return (
		<>
			<Flex vertical className="notifications-page-layout">
				{children}
			</Flex>
		</>
	);
};

export default PageLayout;
