import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';

import { Metric_Header, Business_Attribute_Header } from './data-elements-inventory';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { apiResponse, rowSpanning } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type DropDownValType = {
  id: number | string;
  value: string;
  label: string;
};

const DataElementsInventory: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [searchBy, setSearchby] = useState('Metric Name');
  const [metricName, setMetricName] = useState<DropDownValType[]>([]);
  const [businessAttribute, setBusinessAttribute] = useState<DropDownValType[]>([]);
  const [selectedWorkstream, setSelectedWorkstream] = useState('Finance');
  const [selectedMetric, setSelectedMetric] = useState<DropDownValType[]>([]);
  const [selectedBusinessAttribute, setSelectedBusinessAttribute] = useState<DropDownValType[]>([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const [dataInventoryData, setDataInventoryData] = useState([]);
  const [dataInventoryColumns, setDataInventoryColumns] = useState<any>(Metric_Header);
  const [dataInventoryColumnsOriginal, setDataInventoryColumnsOriginal] = useState<any>(Metric_Header);
  //const [customColumn, setCustumColumn] = useState(customColumnList);
  const [colNameFilter, setColNameFilter] = useState<any>([]);
  const [defaultPage, setDefaultPage] = useState(false);
  const workstream = ['People', 'Finance', 'Supply', 'Brand', 'Pipeline'];

  const gridStyle = useMemo(() => ({ height: '70vh' }), []);

  const gridRef: any = useRef();
  const onFilterChanged = () => {
    //let visibleRowlen = gridRef.current.api.getModel().getRowCount();
    //console.log(gridRef.current.api.getModel().rowsToDisplay);
    const visibleColumns = gridRef.current?.columnApi.getAllDisplayedColumns();
    //console.log('visible Columns', visibleColumns);
  };

  const inventoryGridOptions = {
    defaultColDef: {
      resizable: true,
      initialWidth: 170,
      wrapHeaderText: true,
      sortable: true,
      wrapText: true,
      filter: true,
    },
    rowData: dataInventoryData,
    enableCellTextSelection: true,
    rowBuffer: 200,
    suppressRowTransform: true,
    onColumnMoved: onFilterChanged,
    onFilterChanged: onFilterChanged,
    onSortChanged: onFilterChanged,
    suppressCellFocus: true,
    rowDragManaged: true,
  };

  const getMetric = async () => {
    setOptionLoading(true);
    const payload = {
      work_stream_name: selectedWorkstream,
    };
    try {
      const res = await apiResponse('post', 'get-metric-name', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Metric Data not available for-' + selectedWorkstream);
          setMetricName([]);
          resetColsAttributes();
        } else {
          let options = new Array<DropDownValType>();
          res.data.data.map((obj: { metric_id: string; metric_name: string }, index: number) => {
            options.push({
              id: index,
              value: obj.metric_id,
              label: obj.metric_name,
            });
          });
          setMetricName(options);
          setDefaultPage(true);
          setSelectedMetric(options);
        }
      } else {
        console.log(res);
        toast.error('Metric Data Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Metric Data: Something Went Wrong!!');
    }
    setOptionLoading(false);
  };
  const getBusinessAttribute = async () => {
    setOptionLoading(true);
    const payload = {
      work_stream_name: selectedWorkstream,
    };
    try {
      const res = await apiResponse('post', 'get-business-attributes-name', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Business Attribute Data not available for-' + selectedWorkstream);
          resetColsAttributes();
        } else {
          let options = new Array<DropDownValType>();
          res.data.data.map((obj: { attribute_id: string; attribute_name: string }, index: number) => {
            options.push({
              id: index,
              value: obj.attribute_id,
              label: obj.attribute_name,
            });
          });
          setBusinessAttribute(options);
          setDefaultPage(true);
          setSelectedBusinessAttribute(options);
        }
      } else {
        console.log(res);
        toast.error('Business Attribute: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Businedd Attribute: Something Went Wrong!!');
    }
    setOptionLoading(false);
  };

  useEffect(() => {
    if (selectedWorkstream === '') {
      setMetricName([]);
      setBusinessAttribute([]);
      return;
    }
    if (searchBy === 'Metric Name') {
      getMetric();
    } else if (searchBy === 'Business Attribute') {
      getBusinessAttribute();
    }
  }, [selectedWorkstream]);

  useEffect(() => {
    if (defaultPage === true && (selectedMetric.length > 0 || businessAttribute.length > 0)) {
      getDataInventory();
      setDefaultPage(false);
    }
  }, [selectedMetric, businessAttribute]);

  useEffect(() => {
    if (selectedWorkstream === '') {
      setMetricName([]);
      setBusinessAttribute([]);
      return;
    }
    if (searchBy === 'Metric Name') {
      getMetric();
    } else if (searchBy === 'Business Attribute') {
      getBusinessAttribute();
    }
  }, [selectedWorkstream]);

  useEffect(() => {
    resetColsAttributes();
    setSelectedMetric([]);
  }, [searchBy]);

  const resetColsAttributes = () => {
    setBusinessAttribute([]);
    setDataInventoryColumns([]);
    setDataInventoryColumnsOriginal([]);
    setDataInventoryData([]);
  };

  const getDataInventory = async () => {
    setLoading(true);
    let metricArr = new Array();
    let businessAttributeArr = new Array();
    if (searchBy === 'Metric Name') {
      selectedMetric.map((metric: { value: String }) => {
        metricArr.push(metric.value);
      });
    } else if (searchBy === 'Business Attribute') {
      selectedBusinessAttribute.map((attributes: { value: String }) => {
        businessAttributeArr.push(attributes.value);
      });
    }
    const payload = {
      work_stream_name: selectedWorkstream,
      metric_id: metricArr.toString(),
      attribute_id: businessAttributeArr.toString(),
    };
    try {
      const res = await apiResponse('post', 'get-data-inventory-details', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Data Inventory: Data not available!!');
          setDataInventoryData([]);
          setDataInventoryColumnsOriginal([]);
        } else {
          if (searchBy === 'Metric Name') {
            rowSpanning(res.data.data, 'metric_name');
            setDataInventoryColumns(Metric_Header);
            setDataInventoryColumnsOriginal(Metric_Header);
            setColNameFilter(
              Metric_Header.filter(col => col.field !== 'dashboard_subcomponent' && col.field !== 'source_description').map(
                (obj: { field: string; headerName: string }) => {
                  return { id: obj.field, label: obj.headerName, value: obj.headerName };
                },
              ),
            );
          } else if (searchBy === 'Business Attribute') {
            rowSpanning(res.data.data, 'business_attribute_name');
            setDataInventoryColumns(Business_Attribute_Header);
            setDataInventoryColumnsOriginal(Business_Attribute_Header);
            setColNameFilter(
              Business_Attribute_Header.filter(col => col.field !== 'dashboard_subcomponent' && col.field !== 'source_description').map(
                (obj: { field: string; headerName: string }) => {
                  return { id: obj.field, label: obj.headerName, value: obj.headerName };
                },
              ),
            );
          }
          setDataInventoryData(res.data.data);
        }
      } else {
        console.log(res);
        toast.error('Data Inventory: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Data Inventory: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    const filterTextBox = document?.getElementById('filter-text-box') as HTMLInputElement | null;
    gridRef.current.api.setQuickFilter(filterTextBox?.value || '');
  }, []);

  const customColumnAdd = (arr: []) => {
    let filteredArray = arr.map((obj: { id: string }) => obj.id);
    let filteredCols = dataInventoryColumnsOriginal.filter((obj: { field: string }) => filteredArray.includes(obj.field));
    setColNameFilter(arr);
    setDataInventoryColumns(filteredCols);

    //Onfilterchnaged
    //const visibleColumns = gridRef.current?.columnApi.getAllDisplayedColumns();
    //console.log('visible Columns', visibleColumns);
  };

  return (
    <div>
      {/* <div className="filter-heading">Select your filters-</div> */}
      <div className="data-elements-filter-sec">
        <div className="search-heading">
          <div className={`${searchBy !== 'Metric Name' ? 'search-by-radio unselected' : 'search-by-radio selected'}`}>
            <input
              type="radio"
              id={'metric_name'}
              value="Metric Name"
              onChange={e => {
                setSearchby(e.target.value);
                setSelectedWorkstream('');
                setMetricName([]);
              }}
              checked={searchBy === 'Metric Name'}
              className="di-radio"
            />
            <label className="searchBy-label" htmlFor="metric_name">
              Metric Name
            </label>
          </div>
          <div className={`${searchBy !== 'Business Attribute' ? 'search-by-radio unselected' : 'search-by-radio selected'}`}>
            <input
              type="radio"
              id={'business_attributes'}
              value="Business Attribute"
              onChange={e => {
                setSearchby(e.target.value);
                setSelectedWorkstream('');
                setBusinessAttribute([]);
              }}
              checked={searchBy === 'Business Attribute'}
              className="di-radio"
            />
            <label className="searchBy-label" htmlFor="business_attributes">
              Business Attribute
            </label>
          </div>
          <div className="radio-space"></div>
        </div>

        <div className="d-flex">
          <div className="d-flex flex-direction-column margin-right-25">
            <label className="spl-label">
              Workstream <sup className="sup-star">*</sup>
            </label>
            <select
              className="select-filter-data-elements"
              value={selectedWorkstream}
              onChange={e => {
                setSelectedWorkstream(e.target.value);
                setSelectedBusinessAttribute([]);
                setSelectedMetric([]);
              }}
            >
              <option value="">Select</option>
              {workstream.map((option: string) => (
                <option className="option-style" key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {searchBy === 'Metric Name' ? (
            <>
              <div className="d-flex flex-direction-column">
                <label className="spl-label">
                  Metric <sup className="sup-star">*</sup>
                </label>
                <MultiSelect
                  options={metricName}
                  className="multiselect"
                  value={selectedMetric}
                  onChange={setSelectedMetric}
                  disabled={metricName.length < 1}
                  ClearSelectedIcon={null}
                  isLoading={optionLoading}
                  labelledBy="Metric Name"
                />{' '}
              </div>
              <div className="button-margin  align-submit-button">
                <Button
                  text="Submit"
                  type="secondary"
                  disabled={selectedMetric.length === 0 && selectedBusinessAttribute.length === 0}
                  onClick={() => {
                    getDataInventory();
                  }}
                ></Button>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-direction-column">
                <label className="spl-label">
                  Business Attribute <sup className="sup-star">*</sup>
                </label>
                <MultiSelect
                  options={businessAttribute}
                  className="multiselect"
                  value={selectedBusinessAttribute}
                  onChange={setSelectedBusinessAttribute}
                  disabled={businessAttribute.length < 1}
                  ClearSelectedIcon={null}
                  isLoading={optionLoading}
                  labelledBy="Business Attribute"
                />
              </div>
              <div className=" button-margin align-submit-button">
                <Button
                  text="Submit"
                  type="secondary"
                  disabled={selectedMetric.length === 0 && selectedBusinessAttribute.length === 0}
                  onClick={() => {
                    getDataInventory();
                  }}
                ></Button>
              </div>
            </>
          )}
          {dataInventoryData.length > 0 ? (
            <div className="grid-filter">
              <div className="margin-top-neg-5">
                <label className="spl-label">Columns</label>
                <MultiSelect
                  options={dataInventoryColumnsOriginal.map((obj: { field: string; headerName: string }) => {
                    return { id: obj.field, label: obj.headerName, value: obj.headerName };
                  })}
                  className="multiselect"
                  value={colNameFilter}
                  onChange={(val: []) => customColumnAdd(val)}
                  ClearSelectedIcon={null}
                  isLoading={optionLoading}
                  labelledBy=""
                />
              </div>
              <div className="d-flex flex-direction-column separator">
                <label className="spl-label">Search</label>
                <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
              </div>
              <div className="button-margin align-submit-button">
                <Button
                  text="Clear Column Filter"
                  type="secondary"
                  onClick={() => {
                    clearFilters();
                  }}
                ></Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="data-inventory-table-container">
        {isLoading ? (
          <div className="adm-loader-container">
            <Spin />
          </div>
        ) : (
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact ref={gridRef} columnDefs={dataInventoryColumns} gridOptions={inventoryGridOptions} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataElementsInventory;
