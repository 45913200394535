import {
	Select,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { FetchNotificationResponse } from '../../../../types';

interface PreviewModalFreqProps {
	notification: FetchNotificationResponse;
	setSelectedFrequency: (value: string) => void;
}

const PreviewModalFreq = ({
	notification,
	setSelectedFrequency,
}: PreviewModalFreqProps) => {
	const isADGroup = notification?.is_ad_group;

	const { token } = theme;

	return (
		<Flex vertical gap={3}>
			<Text strong className="notifications-preview-modal-freq">
				Frequency
			</Text>
			<Flex className="notifications-preview-modal-freq-detail" align="center">
				<Text
					style={{
						color: token?.colorTextLabel,
						marginRight: !isADGroup ? '16px' : undefined,
					}}
				>
					{`You receive notifications of updates${!isADGroup && ':'}`}
				</Text>

				{isADGroup ? (
					<Text style={{ color: token?.colorTextLabel, marginRight: '16px' }}>
						&nbsp;{`${notification?.frequency}.`}
					</Text>
				) : (
					<Select
						className="notifications-preview-modal-select"
						onChange={(value) => {
							setSelectedFrequency(value);
						}}
						options={[
							{
								label:
									notification?.frequency[0].toUpperCase() +
									notification?.frequency.slice(1),
								value: notification?.frequency,
							},
						]}
						defaultValue={notification?.frequency}
					/>
				)}
			</Flex>
		</Flex>
	);
};

export default PreviewModalFreq;
