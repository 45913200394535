import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { apiResponse, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import {LineChartComponent } from '@gitlab-rtsensing/component-library';
import { UserInPauseProps } from '../usage-metric-interface';
import { format } from 'date-fns';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';

const VisitChart = (props: UserInPauseProps) => {
  const [isLoading, setLoading] = useState(true);
  const [getVisitDetails, setvisitDetails] = useState([]);
  const [originalVisitDetails, setoriginalVisitDetails] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState('number_of_visits');
  const [chartHeight, setChartHeight] = useState(350);

  useEffect(() => {
    if (getVisitDetails.length === 0) {
      setvisitDetails([]);
      getUsageTrackingData();
    }
  }, []);

  useEffect(() => {
    const filterGroupData = filterMembers(originalVisitDetails, props.userGroupValue, props.includeCoreMember);
    let dataList: any = []; //for merging core-member and non-core member data
    filterGroupData.map((value: any, index: any, self: any) => {
      if (index === self.findIndex((t: any) => t.month_start_date == value.month_start_date)) {
        dataList.push({
          distinct_number_of_users: value.distinct_number_of_users,
          number_of_visits: value.number_of_visits,
          month_start_date: value.month_start_date,
          formated_date: format(new Date(value.month_start_date), 'MMM yyyy'),
        });
      } else {
        dataList.map((data: any) => {
          if (data.month_start_date === value.month_start_date) {
            data.distinct_number_of_users = Number(data.distinct_number_of_users) + Number(value.distinct_number_of_users);
            data.number_of_visits = Number(data.number_of_visits) + Number(value.number_of_visits);
            data.month_start_date = value.month_start_date;
            data.formated_date = format(new Date(value.month_start_date), 'MMM yyyy');
          }
        });
      }
    });
    setvisitDetails(dataList);
  }, [props.userGroupValue, props.includeCoreMember, originalVisitDetails]);

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '14',
      date_type: 'today',
      to_date: '',
      fr_date: '',
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length > 0) {
            const visitDetails = res.data.data.user_metric;
            setoriginalVisitDetails(visitDetails);
            let chartHeight = document?.querySelector('#user-visit-chart')?.clientHeight || 350;
            setChartHeight(chartHeight - 100);
          } else {
            toast.error('Data not available!!');
          }
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  const CustomTooltip = (e: any, payload: any, label: any) => {
    if (e && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload.replaceAll('_', ' ').toUpperCase()} : ${e}`}</p>
        </div>
      );
    }
  };

  return (
    <div className="kpi-metric">
      <div className="metric-header no-left-field">
        <div className="non-filter-note">
          <p>* This metrics is not filterable by Date</p>
        </div>
        <div>
          <span className="ops-text ops-text-dark ops-fw-bold ops-fs-4 gcf-text">Metric </span>
          <select
            className="select-filter-chart"
            onChange={e => {
              setSelectedVisitType(e.target.value);
            }}
            value={selectedVisitType}
          >
            <option className="option-style" key={'number_of_visits'} value={'number_of_visits'}>
              Total Visits
            </option>
            <option className="option-style" key={'distinct_number_of_users'} value={'distinct_number_of_users'}>
              Unique Visitors
            </option>
          </select>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <div className="visit-info">
          {getVisitDetails.length === 0 ? (
            <p className="table-msg">No Data Available</p>
          ) : (
            <div className="visit-chart">
              <LineChartComponent
                data={getVisitDetails}
                dataKeys={[
                  {
                    color: '#3773d2',
                    label: selectedVisitType,
                  },
                ]}
                useTooltip={true}
                //   showLegend={true}
                title="User Visit Information"
                xAxisKey="formated_date"
                yAxisWidth={40}
                chartHeight={chartHeight}
                tick={true}
                tooltipFormatter={CustomTooltip}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default VisitChart;
