import React, { useEffect } from 'react';
import './revert-bootstrap.scss';
import './admin.scss';
import AdminRoutes from './components/admin-routes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, Button } from '@opsdti-global-component-library/amgen-design-system';
import packageJson from '../package.json';
import { apiResponse } from './utility/commonMethods';

type Props = {
  themeService: unknown;
};

const Admin: React.FC = props => {
  const pkgKey = '@opsdti-global-component-library/amgen-design-system';

  const submitPackageVersion = async (pkgVersion: string) => {
    const payload = {
      design_version_list: [
        {
          work_stream_name: 'admin',
          design_system_version: pkgVersion,
        },
      ],
    };
    const res = await apiResponse('post', 'submit-package-version', [], payload);
    console.log('Package Version submitted', res);
  };

  const getPackageVersion = async () => {
    try {
      const res = await apiResponse('get', 'get-package-version', ['?workstream=' + 'admin'], {});
      if (res?.data.status === 'SUCCESS') {
        if (packageJson?.dependencies && packageJson?.dependencies[pkgKey]) {
          let pkgVersion = 'NA';
          console.log('Package Version', packageJson?.dependencies[pkgKey]);
          pkgVersion = packageJson?.dependencies[pkgKey];
          if (res?.data?.data.length === 0) {
            submitPackageVersion(pkgVersion); //First Time call
          } else if (res?.data?.data[0]?.design_system_version !== pkgVersion) {
            submitPackageVersion(pkgVersion); //Subsequent call
          }
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPackageVersion();
  }, []);

  return (
    <div className="admin-sensing-wrapper">
      <div className="admin-sensing-container">
        <ThemeProvider themeService={null}>
          <BrowserRouter>
            <AdminRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Admin;
