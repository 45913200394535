import { Modal } from '@gitlab-rtsensing/component-library';
import './index.scss';
import { lazy, Suspense } from 'react';
import AmgenNetworks from '../../assets/icons/amgen-networks';
import Logo from '../../assets/images/Amgen-Sensing.png';
import { lazyImport } from '../../utility/lazy-import';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';

interface RequestAccessModalProps {
  title?: string;
  opened: boolean;
  onRequestAccessModalClosed: () => void;
}

const RequestForm = lazy(() => lazyImport('@amgen/rtsensing-request-form'));

const RequestAccessModal = (props: RequestAccessModalProps) => {
  const { title = 'Sensing Access Request', opened, onRequestAccessModalClosed } = props;

  return (
    <>
      {opened && (
        <Modal className="request-access-modal" opened={opened} onClose={onRequestAccessModalClosed}>
          <Modal.Header contentClassName="d-flex align-items-center" className="request-modal-header">
            <AmgenNetworks fill="#000" />
            <img src={Logo} alt="amgen logo"></img>
            <Modal.Title className="request-modal-title" title={title} />
          </Modal.Header>

          <Suspense
            fallback={
              <div className="adm-loader-container">
                <Spin />
              </div>
            }
          >
            {<RequestForm />}
          </Suspense>
        </Modal>
      )}
    </>
  );
};

export default RequestAccessModal;
