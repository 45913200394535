import './index.scss';
import { Button, CloseIcon, Card, Modal} from '@opsdti-global-component-library/amgen-design-system';
import { useState } from 'react';

interface RefreshBandModalProps {
  title?: string;
  opened: boolean;
  onRefreshBandClosed: (val: boolean) => void;
  radioSelected: any;
  handleCancel: (val: any) => void;
  handleSubmit: () => void;
}

const RefreshBrandModal = (props: RefreshBandModalProps) => {
  const { opened, onRefreshBandClosed, radioSelected, handleCancel, handleSubmit } = props;
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const submitAPI = async () => {
    const res = Object.values(radioSelected).filter(data => data);
    setSubmitDisabled(true);
    await handleSubmit();
    onModalClose();
    setSubmitDisabled(false);
  };

  // const removeItem = (idToRemove : any) => {
  //   const updateItems = removeData.filter(removeData =>  !== idToRemove);
  //   setRemoveData(updateItems)
  // };

  const onModalClose = () => {
    onRefreshBandClosed(false);
    //Glitch with page Y scroll When modal is opened
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  };

  return (
    <>
      {opened && (
        <Modal className="refresh-brand-modal newsletter-modal refresh-brand-modal-title" width={800} open={opened} onCancel={onModalClose} title="Refresh Brand" > 
          <div className="refresh-brand-modal-body">
            <div className="refresh-brand-modal-table-container">
              <Card>
                <>
                    <div className="">
                      <table className="usage-metric-table brand-refresh-modal-body">
                        <thead>
                          <tr>
                            <th>Table</th>
                            <th>Product</th>
                            <th>Refresh Type </th>
                            <th>Refresh Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {Object.values(radioSelected)
                              .filter(x => x)
                              .map((row: any) => {
                                return (
                                  <tr key={row['process_uuid']}>
                                    <td><strong>{row['table']}</strong> : {row['table_header']}</td>
                                    <td>{row['product']}</td>
                                    <td>{row['refresh_type']}</td>
                                    <td>{row['metric_latest_refresh_dt']}</td>
                                    <td>
                                      <div className="icon-container">
                                        <CloseIcon color="inherit" height={22} width={22} onClick={() => handleCancel(row)} />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        </tbody>
                      </table>
                    </div>
                </>
              </Card>

              <Button text="SUBMIT" type="secondary" className="save-btn" onClick={submitAPI} disabled={submitDisabled}></Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RefreshBrandModal;
